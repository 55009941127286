<template>
  <div>
    <v-card>
      <v-container>
      <v-layout row>
        <v-flex xs12>
          <v-toolbar flat color="transparent">
            <v-toolbar-title>Document Lookup</v-toolbar-title>
          </v-toolbar>
          <v-card flat>
            <v-card-text>
              <b>Please Note:</b> Not all products have been validated. These are for information purposes only and must be validated by the clearing and forwarding agent.
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-autocomplete
              ref="countrySelect"
              hide-no-data
              hide-selected
              label="Origin Country"
              dense
              outlined
              :items="countries"
              clearable
              item-text="name"
              item-value="id"
              :menu-props="{ closeOnContentClick: true }"
              return-object
              v-model="item.originCountry"
              :loading="loadingCountry"
            >
              <template v-slot:selection="data">
                <v-avatar size="32" class="mr-1" v-if="data.item">
                  <v-img
                    contain
                    v-if="data.item.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
                <span v-if="data.item">
                  {{ data.item.name }}
                </span>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img
                    contain
                    v-if="data.item.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              ref="countrySelect"
              hide-no-data
              hide-selected
              label="Destination Country"
              dense
              outlined
              :items="countries"
              clearable
              item-text="name"
              item-value="id"
              :menu-props="{ closeOnContentClick: true }"
              return-object
              v-model="item.destinationCountry"
              :loading="loadingCountry"
            >
              <template v-slot:selection="data">
                <v-avatar size="32" class="mr-1" v-if="data.item">
                  <v-img
                    contain
                    v-if="data.item.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
                <span v-if="data.item">
                  {{ data.item.name }}
                </span>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img
                    contain
                    v-if="data.item.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              label="Type"
              :items="['Product Group', 'Product', 'Variety']"
              v-model="filterProductType"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              ref="countrySelect"
              hide-no-data
              hide-selected
              label="Product"
              dense
              outlined
              :items="filterProducts"
              clearable
              item-text="name"
              item-value="id"
              :menu-props="{ closeOnContentClick: true }"
              v-model="item.product"
              return-object
              :loading="loadingProduct"
              :disabled="!item.originCountry"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.type }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    v-if="data.item.parentProduct"
                    style="font-size: 12px"
                  >
                    Parent: {{ data.item.parentProduct.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              :disabled="!checkLookup"
              color="primary"
              @click="searchDocuments"
              ><v-icon>search</v-icon> Search</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              prepend-inner-icon="search"
              label="Search"
              v-model="search"
              dense
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <div
          class="mt-2"
          v-if="searchingDocuments || documents.length > 0"
          style="min-height: 20vh; max-height: 60vh; overflow-y: auto"
          v-loading="searchingDocuments"
        >
          <v-row class="px-0 mx-0">
            <v-col cols="12" sm="6" class="mx-0 px-0">
              <v-list :key="documentKey">
                <v-subheader>Export Documents</v-subheader>
                <!-- <p>Documents required for product to leave {{item.originCountry.name}}</p> -->
                <v-list-item
                  v-for="document in filterExportDocuments"
                  :key="document.id"
                >
                  <v-list-item-action>
                    <v-chip>{{ document.rank }}</v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ document.name }}
                      <v-tooltip top v-if="document.attestRequired">
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" style="color: red"
                            >(Attest Required)</i
                          >
                        </template>
                        <span style="font-size: 12px">{{
                          document.attestRegulatoryBody.name
                        }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px"
                      class="text-wrap"
                    >
                      {{ document.description }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-for="subDoc in document.variations"
                      :key="subDoc.id"
                    >
                      - {{ subDoc.name }}
                      <v-tooltip top v-if="subDoc.attestRequired">
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" style="color: red"
                            >(Attest Required)</i
                          >
                        </template>
                        <span style="font-size: 12px">{{
                          subDoc.attestRegulatoryBody.name
                        }}</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list :key="documentKey">
                <v-subheader>Import Documents</v-subheader>
                <!-- <p>Documents required for product to enter {{item.destinationCountry.name}}</p> -->
                <v-list-item
                  v-for="document in filterImportDocuments"
                  :key="document.id"
                >
                  <v-list-item-action>
                    <v-chip>{{ document.rank }}</v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ document.name }}
                      <v-tooltip top v-if="document.attestRequired">
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" style="color: red"
                            >(Attest Required)</i
                          >
                        </template>
                        <span style="font-size: 12px" v-if="document.attestRegulatoryBody">{{
                          document.attestRegulatoryBody.name
                        }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px"
                      class="text-wrap"
                    >
                      {{ document.description }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-for="subDoc in document.variations"
                      :key="subDoc.id"
                    >
                      - {{ subDoc.name }}
                      <v-tooltip top v-if="subDoc.attestRequired">
                        <template v-slot:activator="{ on, attrs }">
                          <i v-bind="attrs" v-on="on" style="color: red"
                            >(Attest Required)</i
                          >
                        </template>
                        <span style="font-size: 12px" v-if=" subDoc.attestRegulatoryBody">{{
                          subDoc.attestRegulatoryBody.name
                        }}</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data: () => ({
    countries: [],
    documents: [],
    documentKey: 0,
    filterProductType: "Product",
    loadingCountry: false,
    loadingProduct: false,
    products: [],
    productGroups: [],
    regions: [],
    search: null,
    searchingDocuments: false,
  }),
  watch: {
    "item.originCountry.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadProducts();
        }
      },
    },
  },
  computed: {
    checkLookup() {
      return (
        this.item.product &&
        this.item.originCountry &&
        this.item.destinationCountry
      );
    },
    filterProducts() {
      let result = this.products.data;
      if (result && this.filterProductType) {
        result = result.filter((x) => x.type == this.filterProductType);
      }
      return result;
    },
    filterExportDocuments() {
      let result = this.documents.filter((x) => x.type == "Export");
      if (this.search) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.search.toLowerCase()) ||
            x.variations.some((y) =>
              y.name.toLowerCase().includes(this.search.toLowerCase())
            )
        );
      }
      return result;
    },
    filterImportDocuments() {
      let result = this.documents.filter((x) => x.type == "Import");
      if (this.search) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.search.toLowerCase()) ||
            x.variations.some((y) =>
              y.name.toLowerCase().includes(this.search.toLowerCase())
            )
        );
      }
      return result;
    },
  },
  created() {
    this.loadCountries();
  },
  methods: {
    async loadCountries() {
      this.loadingCountry = true;
      this.regions = await this.$API.getRegions();
      this.countries = [].concat.apply(
        [],
        this.regions.map((x) => x.countryRegions)
      );
      this.loadingCountry = false;
    },
    async loadProducts() {
      this.loadingProduct = true;
      // this.productGroups = await this.$API.listCountryProductGroups(
      //   this.item.originCountry.iso2
      // );
      // let products = await this.$API.listCountryProducts(
      //   this.item.originCountry.iso2,
      //   {}
      // );
      this.products = await this.$API.getProducts({});
      // this.products = products.data;
      this.loadingProduct = false;
    },
    async searchDocuments() {
      this.searchingDocuments = true;
      this.item.productId = this.item.product.id;
      // if(!this.item.productGroupId){
      //     this.item.productGroupId = this.item.product.productGroupId
      // }
      this.documents = [];
      this.item.originCountryId = this.item.originCountry.id;
      this.item.destinationCountryId = this.item.destinationCountry.id;
      this.documents = await this.$API.documentLookup(this.item);
      this.documentKey++;
      this.searchingDocuments = false;
    },
  },
};
</script>
